import React from 'react';
import "./header.css"
import Buttons from "./Buttons";
import HeaderSocials from "./HeaderSocials";
import Image from "../Image/Image";
import { AppFileType } from "../../api/types/AppFileType";

function Header() {
  return (
    <header id="home">
            <div className="container header__container">
                <div className="header__content">
                    <h5>Hello, I'm</h5>
                    <h1>Krystian Krawczyk</h1>
                    <h5 className="text-light">Java Software Developer/Architect</h5>
                    <Buttons />
                </div>
                <HeaderSocials />
                <div className="me">
                    <Image type={AppFileType.MAIN_PHOTO} />
                </div>
                <a href="#contact" className="header__scroll-down">Scroll Down</a>
            </div>
        </header>
  );
}

export default Header;
