import React from 'react';
import { BsLinkedin } from "react-icons/bs";
import { FaGithub, FaStackOverflow, FaTwitter } from "react-icons/fa";
import SocialLink from "../common/SocialLink";
import { LinkType } from "../../api/types/LinkType";

function HeaderSocials() {
  return (
    <div className="header__socials">
      <SocialLink link={"https://www.linkedin.com/in/krystian-krawczyk-647579169/"}
                  linkType={LinkType.LINKEDIN}
                  Icon={<BsLinkedin />} />
      <SocialLink link={"https://github.com/krykra7"}
                  linkType={LinkType.GITHUB}
                  Icon={<FaGithub />} />
      <SocialLink link="https://stackoverflow.com/users/9682906/kry-kra"
                  linkType={LinkType.STACKOVERFLOW}
                  Icon={<FaStackOverflow />} />
    </div>
  );
}

export default HeaderSocials;
