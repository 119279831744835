import React, {useEffect, useState} from 'react';
import "./nav.css"
import {AiOutlineHome, AiOutlineUser} from "react-icons/ai";
import {BiBook, BiMessageSquareDetail} from "react-icons/bi";
import {RiServiceLine} from "react-icons/ri";

function Nav() {
    const [active, setActive] = useState("#")

    const handleScroll = () => {
        const sections = ['#', '#about', '#experience', '#services', '#contact'];
        const viewportHeight = window.innerHeight;
        const scrollPosition = window.scrollY;

        if (scrollPosition < viewportHeight * 0.1) {
            setActive("#");

            return;
        }


        sections.forEach((section) => {
            const element = document.querySelector(section === '#' ? '#home' : section);
            if (element) {
                const rect = element.getBoundingClientRect();
                // Check if section is in viewport (adjust these values as needed)
                const isInView = (
                    rect.top >= 0 &&
                    rect.top <= viewportHeight * 0.4 &&
                    rect.bottom >= viewportHeight * 0.2
                );

                if (isInView) {
                    setActive(section);
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const activeClass = (value: string): string => {
        if (value === active) {
            return "active";
        }

        return "";
    }

    return (
        <nav>
            <a href="#"
               className={activeClass("#")}
               onClick={() => setActive("#")}>
                <AiOutlineHome/>
            </a>
            <a href="#about"
               className={activeClass("#about")}
               onClick={() => setActive("#about")}>
                <AiOutlineUser/>
            </a>
            <a href="#experience"
               className={activeClass("#experience")}
               onClick={() => setActive("#experience")}>
                <BiBook/>
            </a>
            <a href="#services"
               className={activeClass("#services")}
               onClick={() => setActive("#services")}>
                <RiServiceLine/>
            </a>
            <a href="#contact"
               className={activeClass("#contact")}
               onClick={() => setActive("#contact")}>
                <BiMessageSquareDetail/>
            </a>
        </nav>
    );
}

export default Nav;
