import React, { useEffect, useState } from 'react';
import "./projects.css"
import { ProjectResponse } from "../../api/response/ProjectResponse";
import Image from "../Image/Image";
import { LinkType } from "../../api/types/LinkType";
import SocialLink from "../common/SocialLink";

type Props = {
  projects: ProjectResponse[];
}

function Projects({ projects }: Props) {
  const [selectedProject, setSelectedProject] = useState<ProjectResponse | null>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setSelectedProject(null);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setSelectedProject(null);
    }
  };

  return (
    <section id="projects">
      <h5>Projects I worked on</h5>
      <h2>Projects</h2>

      <div className="container projects__container">
        {projects.map((item, index) => {
          return (
            <div
              className="projects__item-wrapper"
              key={index}
              onClick={() => setSelectedProject(item)}
            >
              <article className="projects__item">
                <div className="projects__item-image">
                  <Image imageId={item.imageId} />
                </div>
                <h3>{item.title}</h3>
                <p className="projects__item-description">{item.description}</p>
                <div className="portfolio__item-actions">
                  {item.github && (
                    <SocialLink
                      link={item.github}
                      linkType={LinkType.GITHUB}
                      text="Github"
                      className="btn btn-primary"
                    />
                  )}
                  {item.demo && (
                    <SocialLink
                      link={item.demo}
                      linkType={LinkType.LIVE_DEMO}
                      text="Live Project"
                      className="btn btn-primary"
                    />
                  )}
                </div>
              </article>
            </div>
          );
        })}

        {selectedProject && (
          <div className="projects__dialog-backdrop" onClick={handleBackdropClick}>
            <div className="projects__dialog">
              <div className="projects__dialog-image">
                <Image imageId={selectedProject.imageId} />
              </div>
              <h3>{selectedProject.title}</h3>
              <p className="projects__dialog-description">{selectedProject.description}</p>
              <div className="portfolio__item-actions">
                {selectedProject.github && (
                  <SocialLink
                    link={selectedProject.github}
                    linkType={LinkType.GITHUB}
                    text="Github"
                    className="btn btn-primary"
                  />
                )}
                {selectedProject.demo && (
                  <SocialLink
                    link={selectedProject.demo}
                    linkType={LinkType.LIVE_DEMO}
                    text="Live Project"
                    className="btn btn-primary"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Projects;
