import React from 'react';
import "./footer.css"
import { BsLinkedin } from "react-icons/bs";
import { FaGithub, FaStackOverflow } from "react-icons/fa";
import { LinkType } from "../../api/types/LinkType";
import SocialLink from "../common/SocialLink";

function Footer() {
  return (
    <footer id="footer">
      <a href="#" className="footer__logo">Krystian Krawczyk</a>
      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Projects</a></li>
        {/*<li><a href="#testimonials">Testimonials</a></li>*/}
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <SocialLink link="https://www.linkedin.com/in/krystian-krawczyk-647579169/"
                    linkType={LinkType.LINKEDIN}
                    Icon={<BsLinkedin />} />
        <SocialLink link={"https://github.com/krykra7"}
                    linkType={LinkType.GITHUB}
                    Icon={<FaGithub />} />
        <SocialLink link="https://stackoverflow.com/users/9682906/kry-kra"
                    linkType={LinkType.STACKOVERFLOW}
                    Icon={<FaStackOverflow />} />
      </div>

      <div className="footer__copyright">
        <small>&copy; Krystian Krawczyk. All rights reserved</small>
      </div>
    </footer>
  );
}

export default Footer;
