import React, { FormEvent } from 'react';
import "./contact.css"
import { FaTelegramPlane } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { useApi } from "../../api/common/ApiContext";
import { LinkType } from "../../api/types/LinkType";
import SocialLink from "../common/SocialLink";
import { PostSendMessageRequest } from "../../api/request/PostSendMessageRequest";
import { BsLinkedin } from "react-icons/bs";

const SEND_MESSAGE_TEXT = "Send a message";

function Contact() {
  const { api } = useApi();

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();
    const form: HTMLFormElement = e.target as HTMLFormElement;
    const formData: FormData = new FormData(form);

    api.postSendMessage({
      message: formData.get("message"),
      senderEmail: formData.get("email"),
      senderName: formData.get("name")
    } as PostSendMessageRequest)
      .then(() => {
      }).catch(() => {
    });

    form.reset();
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>contact@krystian-krawczyk.com</h5>
            <SocialLink link={"mailto:contact@krystian-krawczyk.com"}
                        linkType={LinkType.EMAIL}
                        text={SEND_MESSAGE_TEXT}
            />
          </article>
          <article className="contact__option">
            <FaTelegramPlane className="contact__option-icon"/>
            <h4>Telegram</h4>
            <h5>Krystian Krawczyk</h5>
            <SocialLink link={"https://t.me/kkrawczyk1"}
                        linkType={LinkType.TELEGRAM}
                        text={SEND_MESSAGE_TEXT}
            />
          </article>
          <article className="contact__option">
            <BsLinkedin className="contact__option-icon"/>
            <h4>LinkedIn</h4>
            <h5>Krystian Krawczyk</h5>
            <SocialLink link={"https://linkedin.com/in/krystian-krawczyk-647579169"}
                        linkType={LinkType.LINKEDIN}
                        text={SEND_MESSAGE_TEXT}
            />
          </article>
        </div>
        <form onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required/>
          <input type="email" name="email" placeholder="Your Email" required/>
          <textarea name="message" rows={7} placeholder="Your Message" required/>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form>
      </div>
    </section>


  );
}

export default Contact;
