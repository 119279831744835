export enum LinkType {
    EMAIL = "EMAIL",
    TELEGRAM = "TELEGRAM",
    TWITTER = "TWITTER",
    GITHUB = "GITHUB",
    LINKEDIN = "LINKEDIN",
    LIVE_DEMO = "LIVE_DEMO",
    STACKOVERFLOW = "STACKOVERFLOW",
    CV = "CV",
    INSTAGRAM = "INSTAGRAM",
}
